import './bootstrap';
import './browser';
import '@nextapps-be/livewire-sortablejs';

window.preloadjs = require('preload-js');

// Enable all tooltips
$(function() {
    $('[data-toggle="tooltip"]').tooltip();
});
