/**
 * Show the browser and version, information for the school to provide to the
 * helpdesk in case of technical difficulties.
 */

// Full list of supported browser, rendering engines, etc. https://github.com/lancedikson/bowser
import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const browserData = browser.getBrowser();
browserData.version = browserData.version.substring(0, browserData.version.indexOf('.'));

const browserVersion      = document.getElementsByClassName('browser-version');
const errorBrowserVersion = document.getElementsByClassName('error-browser-version');

let errorTest = () => {
    for (let i = 0; i < errorBrowserVersion.length; i++) {
        errorBrowserVersion[i].innerHTML = `${browserData.name} ${browserData.version}`;
    }
};

if (browserVersion) {
    const goodBrowser = () => {
        for (let i = 0; i < browserVersion.length; i++) {
            browserVersion[i].className += ' text-success';
            browserVersion[i].innerHTML = `
                <i class="fa fa-thumbs-up"></i>
                Your browser is ${browserData.name} ${browserData.version}
            `;
        }
    };

    const badBrowser = () => {
        for (let i = 0; i < browserVersion.length; i++) {
            browserVersion[i].className += ' text-danger';
            browserVersion[i].innerHTML = `
                <i class="fa fa-exclamation-triangle"></i>
                Your browser is ${browserData.name} ${browserData.version}
            `;
        }
    };

    const mobile = () => {
        for (let i = 0; i < browserVersion.length; i++) {
            browserVersion[i].className += ' text-danger';
            browserVersion[i].innerHTML = `
                <i class="fa fa-exclamation-triangle"></i>
                Mobile phones are not supported`;
        }
    };

    if (browser.getPlatformType() === 'mobile') {
        mobile(); // Mobile not supported
    } else {
        const isValidBrowser = browser.satisfies({
            'internet explorer': '>=10',
            safari: '10.1',
            chrome: '>=43',
            firefox: '>=31',
            opera: '>=30',
        });

        if (isValidBrowser) {
            goodBrowser(); // Valid browser
        } else {
            badBrowser();
        }
    }
}

/**
 * Change the colour of the user info bar and app header bottom border on the home page
 * to warning danger if any of the data is bad.
 */
const browserDiagnostic = document.getElementById('browser-diagnostics-top');

if (browserDiagnostic) {
    // App Header diagnostics bar element.
    const appHeaderBorder = document.getElementById('app-header');

    // Give the panel the danger styling and change the app-header bottom border colour.
    const dangerStyles = () => {
        browserDiagnostic.className += ' panel-danger';
        appHeaderBorder.style.borderColor = '#CC3200';
    };

    const warnngStyles = () => {
        browserDiagnostic.className += ' panel-warning';
        appHeaderBorder.style.borderColor = '#ffcc00';
    };

    // Subscription information
    const subscriptions = document.getElementById('subscription-diagnostics');
    const textDanger    = subscriptions.getElementsByClassName('text-danger');
    const textDanger2   = browserVersion.classList.contains('text-danger');
    const textWarning   = subscriptions.getElementsByClassName('text-warning');

    // If element children contain class text-danger apply danger styles
    if (textDanger.length > 0) {
        dangerStyles();
    }

    // If element children contain class text-warning apply warning styles
    if (textWarning.length > 0) {
        warnngStyles();
    }

    // If element contains class text-danger apply danger styles
    if (textDanger2) {
        dangerStyles();
    }
}
